<template>
  <v-dialog
    v-model="value" 
    persistent :overlay="false"
    max-width="400px"
    transition="dialog-transition"
  >
    <v-card>
      <v-card-text class="mx-2 subheading">{{ msg }}</v-card-text>
      <v-divider/>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn flat color="primary" @click="onClickConfirm">{{ $t('COMMON_BUTTON_CONFIRM') }}</v-btn>
        <v-btn flat color="primary" @click="onClickCancel">{{ $t('COMMON_BUTTON_CALCEL') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  model: {
    event: 'change'
  },
  props: {
    value: { type: Boolean, required: false, default: false },
    message: { type: String, required: false, default: "" },
  },
  computed: {
    msg() { return this.message ? this.message : this.$t('COMMON_CONFIRM_DEFAULT_MESSAGE') }
  },
  methods: {
    onClickCancel() {
      this.$emit('change', false)
      this.$emit('confirm', false)
    },
    onClickConfirm() {
      this.$emit('change', false)
      this.$emit('confirm', true)
    }
  }
}
</script>

<style>

</style>