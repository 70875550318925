import Vue from 'vue'

const requireComponent = require.context(
  // Look for files in the current directory
  '@/components/u',
  // Do not look in subdirectories
  false,
  // Only include "something[ex)_base-]" prefixed .vue files
  /[\w-]+\.vue$/
)

requireComponent.keys().forEach((fileName) => {
  // Get the component config
  const componentConfig = requireComponent(fileName)
  // Get the PascalCase version of the component name
  const replacedFileName = fileName.replace('./', '').replace('.vue', '')
  const componentName = `Oc${replacedFileName}` // Remove the "./_" from the beginning
  
  // Globally register the component
  Vue.component(componentName, componentConfig.default || componentConfig)
})