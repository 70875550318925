import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  { path: '/login', name: 'Login', component: () => import('@/views/Login.vue') },
  {
    path: '/',
    redirect: { name: 'Check' },
    component: () => import('@/views/Layout.vue'),
    children: [
      { path: 'dashboard', name: 'Dashboard', component: () => import(/* webpackChunkName: "Dashboard" */ '@/views/Dashboard.vue'), meta: { breadcrumb: true, buttons: []}},
      { path: 'policy', name: 'Data_Policy', component: () => import(/* webpackChunkName: "Policy" */ '@/views/Policy.vue'), meta: { breadcrumb: true, buttons: ['copy', 'restore', 'save'], copyType: 'data' }},
      { path: 'event', name: 'Data_Event', component: () => import(/* webpackChunkName: "Event" */ '@/views/Event.vue'), meta: { breadcrumb: true, buttons: ['copy', 'restore', 'save'], copyType: 'data' }},
      { path: 'video', name: 'Data_Video', component: () => import(/* webpackChunkName: "Video" */ '@/views/Video.vue'), meta: { breadcrumb: true, buttons: ['copy', 'restore', 'save'], copyType: 'video' }},
      { path: 'survey', name: 'Survey', component: () => import(/* webpackChunkName: "Survey" */ '@/views/Survey.vue'), meta: { breadcrumb: true, buttons: ['restore', 'save']}},
      { path: 'banner-area', name: 'Banner_Area', component: () => import(/* webpackChunkName: "BannerArea" */ '@/views/BannerArea.vue'), meta: { breadcrumb: true, buttons: ['restore', 'save']}},
      { path: 'banner-item', name: 'Banner_Item', component: () => import(/* webpackChunkName: "BannerItem" */ '@/views/BannerItem.vue'), meta: { breadcrumb: true, buttons: ['restore', 'save']}},
      { path: 'banner-connection', name: 'Banner_Connection', component: () => import(/* webpackChunkName: "BannerConnection" */ '@/views/BannerConnection.vue'), meta: { breadcrumb: true, buttons: ['restore', 'save']}},
      { path: 'message', name: 'Message', component: () => import(/* webpackChunkName: "Message" */ '@/views/Message.vue'), meta: { breadcrumb: true, buttons: ['copy', 'restore', 'save'], copyType: 'message' }},
      { path: 'message-history', name: 'Message_History', component: () => import(/* webpackChunkName: "MessageHistory" */ '@/views/MessageHistory.vue'), meta: { breadcrumb: true, buttons: []}},
      { path: 'task-history', name: 'Task_History', component: () => import(/* webpackChunkName: "TaskHistory" */ '@/views/TaskHistory.vue'), meta: { breadcrumb: true, buttons: []}},
      { path: 'account', name: 'Account', component: () => import(/* webpackChunkName: "Account" */ '@/views/Account.vue'), meta: { breadcrumb: true, buttons: ['restore', 'save']}},
      { path: 'backup', name: 'Backup', component: () => import(/* webpackChunkName: "Backup" */ '@/views/Backup.vue'), meta: { breadcrumb: true, buttons: []}},
      { path: 'comment', name: 'Comment', component: () => import(/* webpackChunkName: "Comment" */ '@/views/Comment.vue'), meta: { breadcrumb: true, buttons: []}},
      { path: 'user', name: 'User', component: () => import(/* webpackChunkName: "Comment" */ '@/views/User.vue'), meta: { breadcrumb: true, buttons: []}},
    ]
  },
  { path: '/check', alias: '*', name: 'Check', component: () => import(/* webpackChunkName: "Check" */ '@/views/Check.vue') },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
