// const camelActual = (str) => {
//   return (str || '').replace(/-(\w)/g, (_, c) => (c ? c.toUpperCase() : ''));
// }

// export function kebab (str) {
//   return (str || '').replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
// }

const capitalize = (string) => {
  const str = string || ''

  return `${str.substr(0, 1).toUpperCase()}${str.slice(1)}`
}

// const camel = (str) => {
//   const camel = (str || '').replace(/-([^-])/g, g => g[1].toUpperCase());

//   return capitalize(camel)
// }

// export function findProduct (store, id) {
//   return store.state.store.products.find(p => p.id === id);
// }

// export function isOnSale (variants) {
//   return variants.some(variant => {
//     return parseFloat(variant.price) < parseFloat(variant.compareAtPrice);
//   });
// }

// export function randomNumber (min, max) {
//   return Math.floor(Math.random() * max) + min;
// }

// export function randomString (length = 5) {
//   let text = '';
//   const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

//   for (let i = 0; i < length; i++) {
//     text += possible.charAt(Math.floor(Math.random() * possible.length));
//   }

//   return text;
// }
const randomElement = (arr = []) => {
  return arr[Math.floor(Math.random() * arr.length)]
}

// const kebab =  (str) => {
//   return (str || '').replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
// }

// const toggleFullScreen = () => {
//   const doc = window.document
//   const docEl = doc.documentElement

//   const requestFullScreen = docEl.requestFullscreen || docEl.mozRequestFullScreen || docEl.webkitRequestFullScreen || docEl.msRequestFullscreen
//   const cancelFullScreen = doc.exitFullscreen || doc.mozCancelFullScreen || doc.webkitExitFullscreen || doc.msExitFullscreen

//   if (!doc.fullscreenElement && !doc.mozFullScreenElement && !doc.webkitFullscreenElement && !doc.msFullscreenElement) {
//     requestFullScreen.call(docEl)
//   } else {
//     cancelFullScreen.call(doc)
//   }
// }
const getDayText = (day) => {
  switch (day) {
    case 0: return '일'
    case 1: return '월'
    case 2: return '화'
    case 3: return '수'
    case 4: return '목'
    case 5: return '금'
    case 6: return '토'
    default: return ''
  }
}

const getDateForm = (date, separator = '-') => {
  const getYear = date.getFullYear()
  let getMonth = date.getMonth() + 1
  let getDate = date.getDate()

  getMonth = getMonth < 10 ? `0${getMonth}` : getMonth
  getDate = getDate < 10 ? `0${getDate}` : getDate

  return `${getYear}${separator}${getMonth}${separator}${getDate}`
}

const getToday = (separator = '-') => {
  const date = new Date()
  
  return getDateForm(date, separator)
}

const getTodayWithTime = () => {
  const date = new Date()
  const getHours = date.getHours()
  const getMinutes = date.getMinutes()
  const getSeconds = date.getSeconds()
  
  return `${getToday('')}_${getHours}${getMinutes}${getSeconds}`
}

const getDateAgo = (ago, separator = '-') => {
  const today = new Date()
  const date = new Date(today.getTime() - (ago * 24 * 60 * 60 * 1000))

  const result = getDateForm(date, separator)

  return result
}

const getYouthYear = () => {
  const date = new Date()
  
  date.setFullYear(date.getFullYear() - 19)
  
  const end = date.getFullYear()
  
  date.setFullYear(date.getFullYear() - 15)
  const start = date.getFullYear()

  return {
    start: start,
    end: end
  }
}

const browserCheck = () => {
  const name = navigator.appName
  let agent = navigator.userAgent.toLowerCase()
  let browser = ''
  
  // MS 계열 브라우저를 구분
  if (name === 'Microsoft Internet Explorer' || agent.indexOf('trident') > -1 || agent.indexOf('edge/') > -1) {
    browser = 'ie'
    if (name === 'Microsoft Internet Explorer') { // IE old version (IE 10 or Lower)
      agent = /msie ([0-9]{1,}[.0-9]{0,})/.exec(agent)
      browser += parseInt(agent[1])
    } else { // IE 11+
      if (agent.indexOf('trident') > -1) { // IE 11
        browser += 11
      } else if (agent.indexOf('edge/') > -1) { // Edge
        browser = 'edge'
      }
    }
  } else if (agent.indexOf('safari') > -1) { // Chrome or Safari
    if (agent.indexOf('opr') > -1) { // Opera
      browser = 'opera'
    } else if (agent.indexOf('chrome') > -1) { // Chrome
      browser = 'chrome'
    } else { // Safari
      browser = 'safari'
    }
  } else if (agent.indexOf('firefox') > -1) { // Firefox
    browser = 'firefox'
  }
  
  return browser
}

function setObject(layer, path, value) {
  const splitedPath = path.split('.')
      
  splitedPath.forEach((item, index) => {
    // if value and is last item
    if (value !== null && index + 1 === splitedPath.length) { layer[item] = value }
    
    layer = layer[item]
  })
  
  return layer
}

function getObject(layer, path) {
  const splitedPath = path.split('.')
      
  splitedPath.forEach((item) => {
    // if value and is last item
    layer = layer[item]
  })
  
  return layer
}

function cloneObject(inObject) {
  let value, key

  if (typeof inObject !== "object" || inObject === null) {
    return inObject // Return the value if inObject is not an object
  }

  // Create an array or object to hold the values
  const outObject = Array.isArray(inObject) ? [] : {}

  for (key in inObject) {
    value = inObject[key]

    // Recursively (deep) copy for nested objects, including arrays
    outObject[key] = cloneObject(value)
  }

  return outObject
}

export default {
  capitalize,
  randomElement,
  getDayText,
  getDateForm,
  getToday,
  getTodayWithTime,
  getDateAgo,
  browserCheck,
  setObject,
  getObject,
  cloneObject,
  getYouthYear
}