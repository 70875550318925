const language = document.querySelector('html').getAttribute('lang')

const environment = {
  language: language,
  waiting: false,
  drawer: true,
}

const common = {
  buttons: {
    restore: { disabled: true, clicked: false },
    save: { disabled: true, clicked: false },
    copy: { clicked: false },
    paste: { disabled: true, clicked: false },
  }
}

const auth = {
  sessionKey: "1234",
  account: {}
  // {
  //   name: "최성식",
  //   email: "starparty@dodohancollabo.com",
  //   department: 'development',
  //   image: require('@/assets/imgs/sample/ddagoo.png'),
  //   authority_level: 'su'
  // }
}

const state = {
  ...environment,
  ...common,
  ...auth
}

export default state