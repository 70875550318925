<template>
  <v-dialog
    :value="value" 
    persistent :overlay="false"
    max-width="450px"
  >
    <v-card>
      <v-card-title class="secondary lighten-2 py-3 title white--text">{{ dialogTitle }}</v-card-title>
      <v-form v-model="valid">
        <v-text-field
          v-for="(content, index) in contents" :key="`id-content-${index}`"
          ref="contents"
          v-model.trim="toContents[index].value"
          :clearable="content.clearable"
          class="ma-4"
          :label="content.label"
          :placeholder="content.placeholder"
          :hint="content.hint"
          :rules="content.rules"
          :persistent-hint="content.persistentHint"
          :required="content.required"
          @keypress.enter="onEnter"
          @keyup.esc="onEsc"
        >
          <template v-if="toContents[index].value&&content.useCheck" v-slot:append>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  icon small
                  color="info"
                  class="ma-0 pa-0"
                  :disabled="!valid"
                  v-on="on"
                  @click="onClickGoToUrl(toContents[index].value)"
                ><v-icon>forward</v-icon></v-btn>
              </template>
              Check
            </v-tooltip>
          </template>
        </v-text-field>
        <!-- v-form 관련 버그로 인해 content가 하나일 경우 엔터 시, 새로고침이 되는 현상 방지용 -->
        <v-text-field
          v-show="false"
        />
      </v-form>
      <v-divider/>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" class="elevation-0" :disabled="!valid" @click="onClickConfirm">{{ $t('COMMON_BUTTON_CONFIRM') }}</v-btn>
        <v-btn color="grey" class="elevation-0" dark @click="onClickCancel">{{ $t('COMMON_BUTTON_CALCEL') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  model: {
    event: 'change'
  },
  props: {
    value: { type: Boolean, required: false, default: false },
    dialogTitle: { type: String, required: false, default: "" },
    // [{ value: "", rules: [], hint: "", placeholder: "", label: "", persistentHint: "", clearable: false, useCheck: false, prefix: "" }]
    contents: { type: Array, required: false, default: () => []},
    focusIndex: { type: [Number, String], required: false, default: 0 },
  },
  data() {
    return {
      valid: false,

      /**
       * [
       *  { value: String, rule: RegExp, prefix: String,
       *    hint: String, placeholder: String, label: String,
       *    persistentHint: Boolean, clearable: Boolean, 
       *    useCheck: Boolean
       *  }
       * ]
       */
      toContents: [],
      configs: [] 
    }
  },
  watch: {
    contents(v) {
      this.toContents = v.reduce((result, item) => {
        result.push({ value: item.value })

        return result
      }, [])

      if (!this.toContents.length) { return }
      
      this.$nextTick(() => {
        const inputEl = this.$refs.contents[this.focusIndex].$el.querySelector('input')
      
        for (const index in this.toContents) {
          const content = this.toContents[index]

          if (this.contents[index].prefix) {
            content.value = content.value ? content.value : this.contents[index].prefix
          }
        }
        
        this.$nextTick(() => {
          inputEl.focus()
          inputEl.select()
        })
      })
    },
  },
  methods: {
    onClickGoToUrl(url) {
      if (!url) { return }

      window.open(url, '_blank')
    },
    onEsc(e) {
      this.close(e)
    },
    onClickCancel(e) {
      this.close(e)
    },
    onClickConfirm(e) {
      this.confirm(e)
    },
    onEnter(e) {
      if (!this.valid) { return }

      this.confirm(e)
    },
    close(e) {
      this.$emit('change', false)
      this.$emit('confirm', false, e)
    },
    confirm(e) {
      this.$emit('change', false)

      this.$emit('confirm', this.toContents, e)
    },
  }
}
</script>

<style>

</style>