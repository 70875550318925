<template>
  <!--
    todo
    1. order 적용
    2. 기회가되면 리스트 가로 사이즈조절(드래그로)
  -->
  <v-card hover class="data-list__wrapper">
    <div class="data-list-controll__wrapper pt-3">
      <v-layout row justify-center>
        <v-btn color="accent darken-1" :disabled="!idOfSelectedItem" @click="onClickCreate">
          <v-icon small class="mr-2">add</v-icon>
          {{ $t('TABLE_LIST_BUTTON_CREATE') }}
        </v-btn>
        <v-btn color="error" :disabled="disabledDelete" @click="onClickDelete">
          <v-icon small class="mr-2">remove</v-icon>
          {{ $t('TABLE_LIST_BUTTON_DELETE') }}
        </v-btn>
      </v-layout>    
      <v-layout row class="mx-4 mb-3" align-end>
        <v-text-field
          v-model.trim="search"
          :placeholder="$t('TABLE_LIST_PLACEHOLDER_SEARCH')"
          class="pa-0"
          hide-details clearable single-line
          @keyup.enter="onSearch"
          @click:clear="onClearSearch"
        />
        <v-btn icon class="ma-0" :disabled="search===keyword">
          <v-icon color="primary" @click="onSearch">search</v-icon>
        </v-btn>
      </v-layout>
      <div class="my-3 text-xs-center">
        <v-pagination :value="pagination.page" :length="pages" class="outline--none" @input="onChangePage"/>
        <v-layout row wrap class="mx-4 my-2 caption">
          <v-flex xs1>
            <v-select
              v-model="pagination.rowsPerPage"
              :items="rowsPerPageItems"
              single-line dense hide-details
              class="pa-0 ma-0 caption text-xs-center"
            />
          </v-flex>
          <v-flex xs10>
            <p v-if="pages" class="mb-0">{{ `${pagination.page} / ${pages}` }}</p>
            <p class="mb-0">{{ `${$t('TABLE_LIST_TOTAL_ITEMS')}: ${totalItems}` }}</p>
          </v-flex>
          <v-flex xs1>
            <slot name="optionButton"/>
          </v-flex>
        </v-layout>
      </div>
      <v-divider/>
    </div>
    <v-data-table
      v-model="selected"
      :loading="loading"
      :headers="headers"
      :items="items"
      item-key="id"
      :pagination.sync="pagination"
      :total-items="totalItems"
      :rows-per-page-items="rowsPerPageItems"
      hide-actions
      :select-all="useSelect"
      :hide-headers="!headers"
      class="data-list-table"
    >
      <template v-slot:progress>
        <v-progress-linear color="accent" indeterminate/>
      </template> 
      <template v-slot:items="props">
        <tr
          class="cursor--pointer"
          :class="{'secondary lighten-5':props.item.id==idOfSelectedItem}"
          @click.stop="onClickRow(props.item, $event)"
        >
          <td v-if="useSelect">
            <v-checkbox
              v-model="props.selected"
              primary
              hide-details
            />
          </td>
          <td class="text-xs-center caption" :class="{'pa-0':useSelect}">{{ props.item.num }}</td>
          <td><span class="break-word text--line-limit-2" :title="props.item[titleName]">{{ props.item[titleName] }}</span></td>
          <td v-if="useCreate" class="text-xs-right pr-4 pl-0 caption break-spaces">{{ new Date(props.item[createdName]).toLocaleDateString() }}</td>
          <slot name="tdIcon" :item="props.item"/>
        </tr>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  props: {
    items: { type: Array, required: false, default: () => []},
    loading: { type: Boolean, required: false, defualt: false },
    headers: { type: Array, required: false, default: undefined },
    totalItems: { type: Number, required: false, default: 0 },
    titleName: { type: String, required: false, default: 'name' },
    useCreate: { type: Boolean, required: false, default: true },
    createdName: { type: String, required: false, default: 'created' },
    initSelected: { type: Boolean, required: false, default: false },
    initPage: { type: Boolean, required: false, default: false },
    useSelect: { type: Boolean, required: false, default: true },
  },
  data() {
    return {
      // pagenation
      pagination: {},
      // table
      selected: [],
      search: this.$route.query.keyword || '',
      rowsPerPageItems: [15, 30, 50],
    }
  },
  computed: {
    pages() {
      if (this.pagination.rowsPerPage === undefined || this.totalItems === 0) { return 0 }

      return Math.ceil(this.totalItems / this.pagination.rowsPerPage)
    },
    keyword: {
      set(v) {
        const toQuery = { ...this.$route.query } 

        toQuery.keyword = v ? v : undefined

        this.$router.replace({ query: toQuery })
      },
      get() { return this.$route.query.keyword || '' }
    },
    idOfSelectedItem: {
      set(v) {
        const toQuery = { ...this.$route.query }
        
        toQuery.id = v ? v : undefined

        this.$router.replace({ query: toQuery })
      },
      get() { return this.$route.query.id }
    },
    page: {
      set(v) {
        const toQuery = { ...this.$route.query }
      
        toQuery.page = v ? v : undefined

        this.$router.replace({ query: toQuery })
      },
      get() { return this.$route.query.page ? this.$route.query.page - 0 : undefined }
    },
    disabledDelete() { return (this.useSelect && !this.selected.length) || (!this.useSelect && !this.idOfSelectedItem) }
  },
  watch: {
    pagination: {
      handler(v) {
        this.$emit('changePagination', v)
      },
      deep: true
    },
    initSelected() {
      this.selected = []
    },
    initPage() {
      this.page = 1
    },
    page(v) {
      this.pagination.page = v
    }
  },
  mounted() {
    if (!this.page) {
      this.page = 1
    }
    this.pagination.page = this.page
  },
  methods: {
    onClickRow(item, e) {
      if (e.target.className.indexOf('v-input--selection-controls__ripple') > -1) { return }
      if (this.idOfSelectedItem == item.id) { return } // idOfSelectedItem가 String number일 경우를 대비해 ===가 아닌 ==사용

      this.idOfSelectedItem = item.id
      this.$emit('select', item, e)
    },
    async onClickCreate(e) {
      this.$emit('create', e)

      if (!await this.$store.dispatch('SessionCheck')) {

        alert('Expired Session')
      }
    },
    onClickDelete(e) {
      this.$emit('delete', this.selected, e)
    },
    onSearch() {
      if (this.keyword === this.search) { return }
      
      this.keyword = this.search
    },
    onClearSearch() {
      this.$nextTick(() => {
        // search는 null이됨
        this.search = ""
        
        if (this.search === this.keyword) { return }
        
        this.keyword = this.search
      })
    },
    // control
    onChangePage(e) {
      if (this.page === e) { return }

      this.page = e
    }
  }
}
</script>

<style>
.data-list-table {
  height: calc(100vh - 58px - 60px - 4px - 215px - 4px - 4px);
  min-height: calc(750px - 58px - 60px - 4px - 215px - 4px - 4px);
  overflow: auto;
}
.data-list-table tbody { border-bottom: 1px solid rgba(0,0,0,0.12); }
</style>