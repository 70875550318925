<template>
  <div id="appRoot">
    <router-view/>
  </div>
</template>

<script>
export default {
  created() {
    let language = this.$cookies.get('language')
    
    if (!language) {
      language = this.$store.state.language 
    } else {
      this.$store.commit('SET_LANGUAGE', language)
    }

    document.querySelector('html').setAttribute('lang', language)
    this.$i18n.locale = language
  }
}
</script>

<style lang="scss">

</style>
