import Vue from 'vue'
import axios from 'axios'
import VueCookies from 'vue-cookies'
import store from '@/store'

// const env = "local"
// const env = "develop"
// const env = "test"
// const env = "staging"
const env = "production"

Vue.prototype.$ENV = env

let domain = ""
let opclPath = ""

if (env === 'develop') {
  domain = 'http://dev-api.opcl.kr/'
  opclPath = 'http://dev.opcl.kr'
} else if (env === 'test') {
  domain = 'http://test-api.opcl.kr/'
  opclPath = 'http://test.opcl.kr'
} else if (env === 'staging') {
  domain = 'https://staging-api.opcl.kr/'
  opclPath = 'http://staging.opcl.kr'
} else if (env === 'production') {
  domain = 'https://api.opcl.kr/'
  opclPath = 'http://opcl.kr'
} else {
  domain = 'http://localhost:8000/'
  opclPath = 'http://localhost:8080'
}
axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded'

Vue.prototype.$DOMAIN = domain
Vue.prototype.$OPCL_PATH = opclPath

const request = {
  get(path) {
    return axios.get(`${domain + path}`)
  },
  post(path, data, config) {
    const methodName = config.headers['Opcl-Method']

    if (!methodName.includes('read') && !methodName.includes('get')) {  
      store.commit('SET_WAITING', true)
    }
    
    return axios.post(`${domain + path}`, data, config)
  },
  delete(path) {
    return axios.delete(`${domain + path}`)
  },
  put(path, data) {
    return axios.put(`${domain + path}`, data)
  }
}

const SESSION_PATH = 'admin/session'

const login = {
  create(data) {
    return request.post(SESSION_PATH, data, {
      headers: {
        "Opcl-Method": "create",
      }
    })
  },
  read(data) {
    return request.post(SESSION_PATH, data, {
      headers: {
        "Opcl-Method": "read",
        "Opcl-Manager-Session-Key": VueCookies.get('ssKey'),
      }
    })
  },
  delete(data) {
    return request.post(SESSION_PATH, data, {
      headers: {
        "Opcl-Method": "delete",
        "Opcl-Manager-Session-Key": VueCookies.get('ssKey'),
      }
    })
  },
  deleteAll(data) {
    return request.post(SESSION_PATH, data, {
      headers: {
        "Opcl-Method": "delete_all",
        "Opcl-Manager-Session-Key": VueCookies.get('ssKey'),
      }
    })
  }
}

const survey = {
  createDetail(data) {
    return request.post('survey/create', data, {
      headers: {
        "Opcl-Method": "create_detail",
        "Opcl-Manager-Session-Key": VueCookies.get('ssKey'),
      }
    })
  },
  readList(data) {
    return request.post('survey/read', data, {
      headers: {
        "Opcl-Method": "read_list",
        "Opcl-Manager-Session-Key": VueCookies.get('ssKey'),
      }
    })
  },
  updateDetail(data) {
    return request.post('survey/update', data, {
      headers: {
        "Opcl-Method": "update_detail",
        "Opcl-Manager-Session-Key": VueCookies.get('ssKey'),
      }
    })
  },
  deleteDetail(data) {
    return request.post('survey/delete', data, {
      headers: {
        "Opcl-Method": "delete_detail",
        "Opcl-Manager-Session-Key": VueCookies.get('ssKey'),
      }
    })
  },
  readSubmittedAnswers(data) {
    return request.post('survey/read', data, {
      headers: {
        "Opcl-Method": "read_submitted_answers",
        "Opcl-Manager-Session-Key": VueCookies.get('ssKey'),
      }
    })
  },
}

const match = {
  matchSubmittedAnswerWithDatas(data) {
    return request.post('match/common', data, {
      headers: {
        "Opcl-Method": "match_submitted_answer_with_datas",
        "Opcl-Manager-Session-Key": VueCookies.get('ssKey'),
      }
    })
  },
}

const common = {
  // search(data) {
  //   return request.post('search/common', data, {
  //     headers: {
  //       "Opcl-Method": "search",
  //       "Opcl-Manager-Session-Key": VueCookies.get('ssKey'),
  //     }
  //   })
  // },
}

const policy = {
  readList(data) {
    return request.post('policy/read', data, {
      headers: {
        "Opcl-Method": "read_list",
        "Opcl-Manager-Session-Key": VueCookies.get('ssKey'),
      }
    })
  },
  readFilters(data) {
    return request.post('policy/read', data, {
      headers: {
        "Opcl-Method": "read_filters",
        "Opcl-Manager-Session-Key": VueCookies.get('ssKey'),
      }
    })
  },
  createDetail(data) {
    return request.post('policy/create', data, {
      headers: {
        "Opcl-Method": "create_detail",
        "Opcl-Manager-Session-Key": VueCookies.get('ssKey'),
      }
    })
  },
  updateDetail(data) {
    return request.post('policy/update', data, {
      headers: {
        "Opcl-Method": "update_detail",
        "Opcl-Manager-Session-Key": VueCookies.get('ssKey'),
      }
    })
  },
  deleteList(data) {
    return request.post('policy/delete', data, {
      headers: {
        "Opcl-Method": "delete_list",
        "Opcl-Manager-Session-Key": VueCookies.get('ssKey'),
      }
    })
  },
  sync(data) {
    return request.post('policy/sync', data, {
      headers: {
        "Opcl-Method": "sync",
        "Opcl-Manager-Session-Key": VueCookies.get('ssKey'),
      }
    })
  },
  // uncheckRecommendAll(data) {
  //   return request.post('policy/update', data, {
  //     headers: {
  //       "Opcl-Method": "uncheck_recommend_all",
  //       "Opcl-Manager-Session-Key": VueCookies.get('ssKey'),
  //     }
  //   })
  // }
}

const event = {
  readList(data) {
    return request.post('events/read', data, {
      headers: {
        "Opcl-Method": "read_list",
        "Opcl-Manager-Session-Key": VueCookies.get('ssKey'),
      }
    })
  },
  readFilters(data) {
    return request.post('events/read', data, {
      headers: {
        "Opcl-Method": "read_filters",
        "Opcl-Manager-Session-Key": VueCookies.get('ssKey'),
      }
    })
  },
  createDetail(data) {
    return request.post('events/create', data, {
      headers: {
        "Opcl-Method": "create_detail",
        "Opcl-Manager-Session-Key": VueCookies.get('ssKey'),
      }
    })
  },
  updateDetail(data) {
    return request.post('events/update', data, {
      headers: {
        "Opcl-Method": "update_detail",
        "Opcl-Manager-Session-Key": VueCookies.get('ssKey'),
      }
    })
  },
  deleteList(data) {
    return request.post('events/delete', data, {
      headers: {
        "Opcl-Method": "delete_list",
        "Opcl-Manager-Session-Key": VueCookies.get('ssKey'),
      }
    })
  },
  sync(data) {
    return request.post('events/sync', data, {
      headers: {
        "Opcl-Method": "sync",
        "Opcl-Manager-Session-Key": VueCookies.get('ssKey'),
      }
    })
  },
  // uncheckRecommendAll(data) {
  //   return request.post('events/update', data, {
  //     headers: {
  //       "Opcl-Method": "uncheck_recommend_all",
  //       "Opcl-Manager-Session-Key": VueCookies.get('ssKey'),
  //     }
  //   })
  // }
}

const video = {
  readList(data) {
    return request.post('videos/read', data, {
      headers: {
        "Opcl-Method": "read_list",
        "Opcl-Manager-Session-Key": VueCookies.get('ssKey'),
      }
    })
  },
  readFilters(data) {
    return request.post('videos/read', data, {
      headers: {
        "Opcl-Method": "read_filters",
        "Opcl-Manager-Session-Key": VueCookies.get('ssKey'),
      }
    })
  },
  createDetail(data) {
    return request.post('videos/create', data, {
      headers: {
        "Opcl-Method": "create_detail",
        "Opcl-Manager-Session-Key": VueCookies.get('ssKey'),
      }
    })
  },
  updateDetail(data) {
    return request.post('videos/update', data, {
      headers: {
        "Opcl-Method": "update_detail",
        "Opcl-Manager-Session-Key": VueCookies.get('ssKey'),
      }
    })
  },
  deleteList(data) {
    return request.post('videos/delete', data, {
      headers: {
        "Opcl-Method": "delete_list",
        "Opcl-Manager-Session-Key": VueCookies.get('ssKey'),
      }
    })
  },
  sync(data) {
    return request.post('videos/sync', data, {
      headers: {
        "Opcl-Method": "sync",
        "Opcl-Manager-Session-Key": VueCookies.get('ssKey'),
      }
    })
  }
  // uncheckRecommendAll(data) {
  //   return request.post('videos/update', data, {
  //     headers: {
  //       "Opcl-Method": "uncheck_recommend_all",
  //       "Opcl-Manager-Session-Key": VueCookies.get('ssKey'),
  //     }
  //   })
  // }
}

const MANAGER_PATH = 'admin/manager'

const account = {
  create(data) {
    return request.post(MANAGER_PATH, data, {
      headers: {
        "Opcl-Method": "create",
        "Opcl-Manager-Session-Key": VueCookies.get('ssKey'),
      }
    })
  },
  read(data) {
    return request.post(MANAGER_PATH, data, {
      headers: {
        "Opcl-Method": "read",
        "Opcl-Manager-Session-Key": VueCookies.get('ssKey'),
      }
    })
  },
  update(data) {
    return request.post(MANAGER_PATH, data, {
      headers: {
        "Opcl-Method": "update",
        "Opcl-Manager-Session-Key": VueCookies.get('ssKey'),
      }
    })
  },
  delete(data) {
    return request.post(MANAGER_PATH, data, {
      headers: {
        "Opcl-Method": "delete",
        "Opcl-Manager-Session-Key": VueCookies.get('ssKey'),
      }
    })
  }
}

const taskHistory = {
  read(data) {
    return request.post('admin/log', data, {
      headers: {
        "Opcl-Method": "read",
        "Opcl-Manager-Session-Key": VueCookies.get('ssKey'),
      }
    })
  }
}

const BACKUP_PATH = 'admin/backup'

const backup = {
  create(data) {
    return request.post(BACKUP_PATH, data, {
      headers: {
        "Opcl-Method": "create",
        "Opcl-Manager-Session-Key": VueCookies.get('ssKey'),
      }
    })
  },
  read(data) {
    return request.post(BACKUP_PATH, data, {
      headers: {
        "Opcl-Method": "read",
        "Opcl-Manager-Session-Key": VueCookies.get('ssKey'),
      }
    })
  },
  update(data) {
    return request.post(BACKUP_PATH, data, {
      headers: {
        "Opcl-Method": "update",
        "Opcl-Manager-Session-Key": VueCookies.get('ssKey'),
      }
    })
  },
  delete(data) {
    return request.post(BACKUP_PATH, data, {
      headers: {
        "Opcl-Method": "delete",
        "Opcl-Manager-Session-Key": VueCookies.get('ssKey'),
      }
    })
  },
}

const UPLOAD_PATH = 'admin/upload'

const upload = {
  upload(data) {
    return request.post(UPLOAD_PATH, data, {
      headers: {
        "Opcl-Method": "upload",
        "Opcl-Manager-Session-Key": VueCookies.get('ssKey'),
      }
    })
  },
  deleteList(data) {
    return request.post(UPLOAD_PATH, data, {
      headers: {
        "Opcl-Method": "delete_list",
        "Opcl-Manager-Session-Key": VueCookies.get('ssKey'),
      }
    })
  }
}

const BANNER_CREATE = 'banner/create'
const BANNER_READ = 'banner/read'
const BANNER_UPDATE = 'banner/update'
const BANNER_DELETE = 'banner/delete'

const banner = {
  // banner area
  createBannerArea(data) {
    return request.post(BANNER_CREATE, data, {
      headers: {
        "Opcl-Method": "create_banner_area",
        "Opcl-Manager-Session-Key": VueCookies.get('ssKey'),
      }
    })
  },
  readBannerAreaList(data) {
    return request.post(BANNER_READ, data, {
      headers: {
        "Opcl-Method": "read_banner_area_list",
        "Opcl-Manager-Session-Key": VueCookies.get('ssKey'),
      }
    })
  },
  updateBannerArea(data) {
    return request.post(BANNER_UPDATE, data, {
      headers: {
        "Opcl-Method": "update_banner_area",
        "Opcl-Manager-Session-Key": VueCookies.get('ssKey'),
      }
    })
  },
  deleteBannerAreaList(data) {
    return request.post(BANNER_DELETE, data, {
      headers: {
        "Opcl-Method": "delete_banner_area_list",
        "Opcl-Manager-Session-Key": VueCookies.get('ssKey'),
      }
    })
  },
  // banner item
  createBannerItem(data) {
    return request.post(BANNER_CREATE, data, {
      headers: {
        "Opcl-Method": "create_banner",
        "Opcl-Manager-Session-Key": VueCookies.get('ssKey'),
      }
    })
  },
  readBannerItemList(data) {
    return request.post(BANNER_READ, data, {
      headers: {
        "Opcl-Method": "read_banner_list",
        "Opcl-Manager-Session-Key": VueCookies.get('ssKey'),
      }
    })
  },
  updateBannerItem(data) {
    return request.post(BANNER_UPDATE, data, {
      headers: {
        "Opcl-Method": "update_banner",
        "Opcl-Manager-Session-Key": VueCookies.get('ssKey'),
      }
    })
  },
  deleteBannerItemList(data) {
    return request.post(BANNER_DELETE, data, {
      headers: {
        "Opcl-Method": "delete_banner_list",
        "Opcl-Manager-Session-Key": VueCookies.get('ssKey'),
      }
    })
  },
  // banner connection
  createConnection(data) {
    return request.post(BANNER_CREATE, data, {
      headers: {
        "Opcl-Method": "create_connection",
        "Opcl-Manager-Session-Key": VueCookies.get('ssKey'),
      }
    })
  },
  readConnectionList(data) {
    return request.post(BANNER_READ, data, {
      headers: {
        "Opcl-Method": "read_connection_list",
        "Opcl-Manager-Session-Key": VueCookies.get('ssKey'),
      }
    })
  },
  updateConnection(data) {
    return request.post(BANNER_UPDATE, data, {
      headers: {
        "Opcl-Method": "update_connection",
        "Opcl-Manager-Session-Key": VueCookies.get('ssKey'),
      }
    })
  },
  deleteConnectionList(data) {
    return request.post(BANNER_DELETE, data, {
      headers: {
        "Opcl-Method": "delete_connection_list",
        "Opcl-Manager-Session-Key": VueCookies.get('ssKey'),
      }
    })
  },
}

const COMMENT_PATH = 'admin/comment'

const comment = {
  readComments(data) {
    return request.post(COMMENT_PATH, data, {
      headers: {
        "Opcl-Method": "read",
        "Opcl-Manager-Session-Key": VueCookies.get('ssKey'),
      }
    })
  },
  updateComment(data) {
    return request.post(COMMENT_PATH, data, {
      headers: {
        "Opcl-Method": "update",
        "Opcl-Manager-Session-Key": VueCookies.get('ssKey'),
      }
    })
  },
  deleteComment(data) {
    return request.post(COMMENT_PATH, data, {
      headers: {
        "Opcl-Method": "delete",
        "Opcl-Manager-Session-Key": VueCookies.get('ssKey'),
      }
    })
  }
}

const USER_PATH = 'admin/user'

const user = {
  readUsers(data) {
    return request.post(USER_PATH, data, {
      headers: {
        'Opcl-Method': 'read',
        'Opcl-Manager-Session-Key': VueCookies.get('ssKey')
      }
    })
  },
  createUserBlock(data) {
    return request.post(USER_PATH, data, {
      headers: {
        'Opcl-Method': 'create_block',
        'Opcl-Manager-Session-Key': VueCookies.get('ssKey')
      }
    })
  },
  readUserBlocks(data) {
    return request.post(USER_PATH, data, {
      headers: {
        'Opcl-Method': 'read_blocks',
        'Opcl-Manager-Session-Key': VueCookies.get('ssKey')
      }
    })
  },
  updateUserBlocks(data) {
    return request.post(USER_PATH, data, {
      headers: {
        'Opcl-Method': 'update_block',
        'Opcl-Manager-Session-Key': VueCookies.get('ssKey')
      }
    })
  },
  updateOperator(data) {
    return request.post(USER_PATH, data, {
      headers: {
        'Opcl-Method': 'update_operator',
        'Opcl-Manager-Session-Key': VueCookies.get('ssKey')
      }
    })
  },
  deleteUserBlock(data) {
    return request.post(USER_PATH, data, {
      headers: {
        'Opcl-Method': 'delete_block',
        'Opcl-Manager-Session-Key': VueCookies.get('ssKey')
      }
    })
  },
  deleteUserBlockAll(data) {
    return request.post(USER_PATH, data, {
      headers: {
        'Opcl-Method': 'delete_block_all',
        'Opcl-Manager-Session-Key': VueCookies.get('ssKey')
      }
    })
  },
  downloadExcel() {
    return axios.post(`${domain + USER_PATH}`, {}, {
      responseType: 'blob',
      headers: {
        'Opcl-Method': 'download_excel',
        'Opcl-Manager-Session-Key': VueCookies.get('ssKey'),
        // 'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      }
    })
  }
}

const MESSAGE_PATH = 'admin/message'

const message = {
  createMessage(data) {
    return request.post(MESSAGE_PATH, data, {
      headers: {
        'Opcl-Method': 'create',
        'Opcl-Manager-Session-Key': VueCookies.get('ssKey')
      }
    })
  },
  readMessages(data) {
    return request.post(MESSAGE_PATH, data, {
      headers: {
        'Opcl-Method': 'read',
        'Opcl-Manager-Session-Key': VueCookies.get('ssKey')
      }
    })
  },
  readMessageHistory(data) {
    return request.post(MESSAGE_PATH, data, {
      headers: {
        'Opcl-Method': 'read_logs',
        'Opcl-Manager-Session-Key': VueCookies.get('ssKey')
      }
    })
  },
  updateMessage(data) {
    return request.post(MESSAGE_PATH, data, {
      headers: {
        'Opcl-Method': 'update',
        'Opcl-Manager-Session-Key': VueCookies.get('ssKey')
      }
    })
  },
  deleteMessages(data) {
    return request.post(MESSAGE_PATH, data, {
      headers: {
        'Opcl-Method': 'delete_list',
        'Opcl-Manager-Session-Key': VueCookies.get('ssKey')
      }
    })
  },
  readRecipientCount(data) {
    return request.post(MESSAGE_PATH, data, {
      headers: {
        'Opcl-Method': 'read_recipient_count',
        'Opcl-Manager-Session-Key': VueCookies.get('ssKey')
      }
    })
  },
  readCountOfRead(data) {
    return request.post(MESSAGE_PATH, data, {
      headers: {
        'Opcl-Method': 'read_count_of_read',
        'Opcl-Manager-Session-Key': VueCookies.get('ssKey')
      }
    })
  },
  sendMessage(data) {
    return request.post(MESSAGE_PATH, data, {
      headers: {
        'Opcl-Method': 'send',
        'Opcl-Manager-Session-Key': VueCookies.get('ssKey')
      }
    })
  }
}

export default {
  login, policy, event, video, common,
  survey, match, account, taskHistory, backup,
  upload, banner, comment, user, message
}