<template>
  <v-dialog
    :value="value" 
    :overlay="false"
    max-width="700px"
    persistent
  >
    <v-card>
      <v-card-title class="secondary lighten-2 py-3 title white--text">{{ dialogTitle }}</v-card-title>
      <codemirror
        id="code-dialog"
        ref="code"
        v-model="toContent"
        :options="cmOption"
        @ready="onReady"
        @keyHandled="onEsc"
      />
      <v-divider/>
      <v-card-actions>
        <v-tooltip bottom>
          <template v-slot:activator="{on}">
            <v-btn icon v-on="on" @click="onClickHelp"><v-icon color="primary">help</v-icon></v-btn>
          </template>
          <span>Help</span>
        </v-tooltip>
        <v-tooltip v-model="hintSwitch" :position-x="2000">
          <pre class="pre-wrap">{{ hint }}</pre>
        </v-tooltip>
        <v-btn color="info" class="elevation-0" @click="onClickRestore">{{ $t('COMMON_BUTTON_RESTORE') }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" class="elevation-0" @click="onClickConfirm">{{ $t('COMMON_BUTTON_CONFIRM') }}</v-btn>
        <v-btn color="grey" class="elevation-0" dark @click="onClickCancel">{{ $t('COMMON_BUTTON_CALCEL') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { codemirror } from 'vue-codemirror'
import 'codemirror/lib/codemirror.css'

// language
import 'codemirror/mode/javascript/javascript.js'

// require active-line.js
import 'codemirror/addon/selection/active-line.js'
// closebrackets
import 'codemirror/addon/edit/closebrackets.js'
// keyMap
import 'codemirror/mode/clike/clike.js'
import 'codemirror/addon/edit/matchbrackets.js'
import 'codemirror/addon/comment/comment.js'
import 'codemirror/addon/dialog/dialog.js'
import 'codemirror/addon/dialog/dialog.css'
import 'codemirror/addon/search/searchcursor.js'
import 'codemirror/addon/search/search.js'
import 'codemirror/keymap/sublime.js'

export default {
  components: {
    codemirror
  },
  model: {
    event: 'change'
  },
  props: {
    value: { type: Boolean, required: false, default: false },
    dialogTitle: { type: String, required: false, default: "" },
    // [{ value: "", rules: [], hint: "", placeholder: "", label: "", persistentHint: "", clearable: false, useCheck: false, prefix: "" }]
    content: { type: String, required: false, default: "" },
    hint: { type: String, required: false, default: "" }
  },
  data() {
    return {
      toContent: "",
      hintSwitch: false,
      cmOption: {
        autoCloseBrackets: true,
        tabSize: 2,
        styleActiveLine: true,
        lineNumbers: true,
        line: true,
        mode: {
          name: 'javascript',
          json: true
        },
        keyMap: "sublime",
        theme: "default",
      },
      cm: undefined,
    }
  },
  watch: {
    value(v) {
      if (v) {
        setTimeout(() => {
          this.toContent = this.content
          this.cm.refresh()
          this.cm.focus()
        }, 400)
      } else {
        this.toContent = ''
      }
    },
  },
  methods: {
    onClickHelp() {
      this.hintSwitch = !this.hintSwitch
    },
    onReady(e) {
      this.cm = e
      this.cm.setSize('', 500)
    },
    onClickRestore() {
      if (!confirm(this.$t('COMMON_CONFIRM_RESTORE'))) { return }

      this.toContent = this.content
      this.cm.focus()
    },
    onEsc(cm, key, e) {
      if (key === 'Esc') {
        if (!confirm(this.$t('COMMON_TEXT_CONFIRM_PRVENT_ROUTE'))) { return }
        this.close(e)
      }
    },
    onClickCancel(e) {
      this.close(e)
    },
    onClickConfirm(e) {
      this.confirm(e)
    },
    onEnter(e) {
      if (!this.valid) { return }

      this.confirm(e)
    },
    close(e) {
      this.$emit('change', false)
      this.$emit('confirm', false, e)
    },
    confirm(e) {
      this.$emit('change', false)

      this.toContent = this.toContent.replace(this.hint, '')
      this.$emit('confirm', this.toContent, e)
    },
  }
}
</script>

<style>

</style>