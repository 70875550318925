import Vue from 'vue'
import './addOcComponents'
import baseUtils from './utils/baseUtils'

Vue.mixin({
  computed: {
    waiting: {
      set(v) { this.$store.commit('SET_WAITING', v) },
      get() { return this.$store.state.waiting }
    },
  },
  methods: {
    errorCheck(err) {
      console.log(err)
      window.onbeforeunload = undefined
      if (!err.response) { 
        alert('Sorry, There is an unknown problem.')
        
        return
      }
      
      const errorCode = err.response.status
      const errorData = err.response.data
      const errorDataKeys = Object.keys(errorData)

      if (errorCode === 403 && (errorData.detail.indexOf('SESSION') > -1)) {
        this.$cookies.remove('ssKey')
        alert(this.$t('COMMON_ALERT_EXPIRED_SESSION'))
        
        if (this.$route.name === 'Login') { return }
        this.$router.replace({ name: 'Login' })
      } else if (errorDataKeys.length) {
        const errorMsgs = []
        let errorAlert = ""
        let num = 0

        for (const key of errorDataKeys) {
          if (Array.isArray(errorData[key])) {
            for (const index in errorData[key]) {
              num += 1
              errorMsgs.push(`${num}. ${key}: ${errorData[key][index]}`)
            }
          } else if (errorData[key] === Object(errorData[key])) {
            for (const k in errorData[key]) {
              num += 1
              errorMsgs.push(`${num}. ${key}: ${errorData[key][k]}`)
            }
          } else {
            num += 1
            errorMsgs.push(`${num}. ${errorData[key]}`)
          }
        }

        errorAlert = errorMsgs.join('\n')

        alert(errorAlert)
      } else {
        alert(`Fail..\n${err.response.type}`)
      }
      this.waiting = false
    },
    extendSession() {
      const sessionKey = this.$cookies.get('ssKey')

      this.$cookies.set('ssKey', sessionKey)
      this.waiting = false
    },
  }
})

Vue.prototype.$baseUtils = baseUtils