<template>
  <v-layout
    column fill-height justify-center align-center
    class="loading__wrapper"
    :class="{'smAndDown': $vuetify.breakpoint.smAndDown}"
  >
    <v-progress-circular
      :size="size"
      indeterminate
      :color="color"
      :width="width"
    />
    <span
      :class="`
        mt-${$vuetify.breakpoint.smAndDown?20:40}px
        Noto-Sans-KR-${$vuetify.breakpoint.smAndDown?15:20}px
      `"
      class="color--link bold"
    >{{ text?text:$t("COMMON_MSG_WAITING") }}</span>
  </v-layout>
</template>

<script>
export default {
  props: {
    size: { type: [String, Number], required: false, default: 100 },
    width: { type: [String, Number], required: false, default: 15 },
    color: { type: String, required: false, default: '#00ffcc' },
    text: { type: String, required: false, default: "" },
  },
}
</script>

<style scoped>
  .loading__wrapper {
    position: fixed;
    top:0;
    left:0;
    width: 100%;
    border-radius: 0;
    z-index: 100;
    background: rgba(255, 255, 255, 0.8);
    z-index: 9999;
  }
  .loading__wrapper.smAndDown {
    border-radius: 0 20px 20px 0;
  }
</style>