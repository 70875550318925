<template>
  <v-layout row justify-center>
    <v-dialog
      :value="value"
      :hide-overlay="false"
      scrollable persistent
      max-width="500px"
      transition="dialog-transition"
      @keydown.esc.stop="onClose"
    >
      <v-card>
        <v-card-title primary-title>
          <div class="full-width">
            <v-layout row align-start justify-space-between>
              <p class="headline">{{ title }}</p>
              <v-switch
                v-if="isImage"
                v-model="params.compress"
                small
                :label="$t('UPLOAD_IMAGE_COMPRESS')"
                class="caption mt-0 justify-end"
              />
            </v-layout>
            <p v-if="subTitle" class="pl-1 mb-1">{{ subTitle }}</p>
            <p class="caption pl-1 mb-1">{{ $t('UPLOAD_TIP') }} </p>
            <p class="caption pl-1 mb-0">{{ `File size: up to ${fileSize / 1024 / 1024}MB` }}</p>
          </div>
        </v-card-title>
        <v-card-text class="pb-0">
          <v-container class="py-0" grid-list-xs>
            <v-list v-if="fileValues.length" class="blue lighten-4 file-list">
              <v-list-tile 
                v-for="(file, index) in fileValues" :key="`${inputId}-${index}`"
                avatar
              >
                <v-list-tile-avatar v-if="isImage" color="white">
                  <v-img
                    max-height="50px"
                    max-width="50px"
                    :src="file.url"
                  />
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title>
                    <input
                      v-model.trim="file.name"
                      placeholder="File.name"
                      class="file-name"
                    />
                  </v-list-tile-title>
                  <v-list-tile-sub-title class="pl-2">{{ ( file.size/1024).toFixed(2)+' kB' }}</v-list-tile-sub-title>
                </v-list-tile-content>
                <v-list-tile-action>
                  <v-icon v-if="file.success">cloud_done</v-icon>
                  <v-icon v-else-if="file.error">cloud_off</v-icon>
                  <v-progress-circular v-else-if="file.active" size="20" indeterminate color="grey"/>
                </v-list-tile-action>
                <v-list-tile-action>
                  <v-btn icon ripple @click.prevent="onClickDeleteItem(index)">
                    <v-icon color="primary">remove_circle</v-icon>
                  </v-btn>
                </v-list-tile-action>
              </v-list-tile>
            </v-list>
            <label v-else :for="inputId" class="cursor--pointer">
              <div
                class="blue lighten-4 text-xs-center py-5"
                :class="{'drop-active':$refs.upload&&$refs.upload.dropActive}"
              >
                <pre class="subheading bold grey--text text--darken-2 pre-wrap"> {{ $t('UPLOAD_PLACE_HOLDER_UPLOAD_AREA') }} </pre>
              </div>
            </label>
          </v-container>
          <v-layout row justify-center align-end class="pa-0 mt-3">
            <FileUpload
              ref="upload"
              v-model="fileValues"
              class="cursor--pointer"
              :data="params"
              :input-id="inputId"
              :extensions="extensions"
              :accept="accept"
              :headers="headers"
              :post-action="postAction"
              :multiple="multiple"
              :drop="true"
              :drop-directory="false"
              :size="fileSize || 0"
              @input-filter="onInputFilter"
              @input-file="onInputFile"
            >
              <v-btn class="primary">{{ $t('UPLOAD_BUTTON_SELECT_FILES') }}</v-btn>
            </FileUpload>
            <div v-if="uploadFromDialog">
              <v-btn
                v-if="!$refs.upload||!$refs.upload.active"
                class="primary"
                :disabled="!fileValues.length"
                @click.prevent="onClickStartUpload"
              >{{ $t('UPLOAD_BUTTON_START_UPLOAD') }}</v-btn>
              <!-- <v-btn class="primary"
                v-else-if="file.error && file.error !== 'compressing' && $refs.upload.features.html5" @click.prevent="$refs.upload.update(file, {active: true, error: '', progress: '0.00'})">
                Retry Upload
              </v-btn> -->
              <v-btn
                v-else
                class="primary"
                @click.prevent="!$refs.upload.active"
              >{{ $t('UPLOAD_BUTTON_STOP_UPLOAD') }}</v-btn>
            </div>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <!-- <v-btn color="blue darken-1" flat @click.prevent="onClickDeleteAll">Delete</v-btn> -->
          <v-btn
            color="blue darken-1" 
            flat
            :disabled="!fileValues.length"
            @click.prevent="onClickDeleteAll"
          >{{ $t('COMMON_TEXT_DELETE') }}</v-btn>
          <v-btn color="blue darken-1" flat @click.prevent="onClose">{{ $t('COMMON_BUTTON_CLOSE') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import FileUpload from 'vue-upload-component'
export default {
  components: {
    FileUpload
  },
  model: {
    event: 'change'
  },
  props: {
    type: { type: String, required: false, default: '' },
    value: { type: Boolean, required: true },
    inputId: { type: String, required: true },
    files: { type: Array, required: true },
    title: { type: String, required: false, default: 'Title' },
    subTitle: { type: String, required: false, default: '' },
    extensions: { type: [Array, undefined], required: false, default: undefined },
    accept: { type: [String, undefined], required: false, default: undefined },
    apiPath: { type: String, required: false, default: 'admin/upload' },
    multiple: { type: Boolean, required: false, default: true },
    fileSize: { type: Number, required: false, default: 5 * 1024 * 1024 },
    params: { type: Object, require: false, default: () => { return {} } },
    headers: { type: Object, require: false, default: () => { return {} } },
    prefix: { type: String, required: false, default: '' },

    /**
     * uploadFromDialog, selectAndClse, saveStart (+ select emit)는
     * @/components/scenariosettings/Second.vue 에서
     * 이미지 선택 시, 다이얼로그 외부에서 이미지 미리보기를 구현하기 위해 필요한 파라미터
     * 예시는, 시나리오 설정의 2단계 봇정보 등록과정에서 확인 가능
     * */
    uploadFromDialog: { type: Boolean, required: false, default: true },
    selectAndClose: { type: Boolean, required: false, default: false },
    saveStart: { type: Boolean, required: false, default: false },
  },
  data() {
    return {
      DOMAIN: "",
      fileValues: [],
    }
  },
  computed: {
    postAction() { return this.$DOMAIN + this.apiPath },
    isImage() { return ['image', 'thumbnail'].indexOf(this.type) > -1 },
  },
  watch: {
    saveStart(v) {
      if (v) {
        this.onClickStartUpload()
      }
    },
    files(v) {
      this.fileValues = v
    },
  },
  methods: {
    onClickStartUpload() {
      this.$refs.upload.active = true
      for (const file of this.files) {
        if (file.error && file.error !== 'compressing' && this.$refs.upload.features.html5) {
          this.$refs.upload.update(file, { active: true, error: '', progress: '0.00' })
        }
      }
    },
    onInputFilter(newFile, oldFile, prevent) {
      if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
        let isAccepted = false

        if (this.type === 'file') {
          isAccepted = true
        } else if (this.accept && newFile.type && this.accept.indexOf(newFile.type) > -1) {
          isAccepted = true
        } else if (this.extensions) {
          for (const acception of this.extensions) {
            const _fileLen = newFile.name.length
            const _lastDot = newFile.name.lastIndexOf('.')
            const _fileExt = newFile.name.substring(_lastDot, _fileLen).toLowerCase()

            if (_fileExt === '.' + acception) {
              isAccepted = true
              
              break
            }
          }
        }

        if (!isAccepted) {
          alert('Wrong file type')
          prevent()
        }
        newFile.url = ''
        
        const url = window.URL || window.webkitURL

        if (url && url.createObjectURL) {
          newFile.url = url.createObjectURL(newFile.file)
        }
      }
    },
    onInputFile(newFile, oldFile) {
      if (newFile && !oldFile) {
        // Add file 
        if (newFile.size > this.fileSize) {
          alert(this.$t('UPLOAD_ALERT_MAXIMUM').replace('[]', this.fileSize / 1024 / 1024))
          this.fileValues.splice(this.fileValues.length - 1, 1)
          
          return
        }

        if (this.selectAndClose) {
          this.$emit('select', newFile)
          this.$emit('change', false)
        }

        if (this.prefix) {
          newFile.name = `${this.prefix}${newFile.name}`
        }

        if (!this.multiple) {
          this.fileValues = [newFile]

          return
        }

        if (this.fileValues.indexOf(newFile) > 0 && newFile.data.category === 'image') {
          newFile.data.thumbnail = false
        }
      }
      if (newFile && oldFile) {
        // Update file
        // this.dialogConfig.uploadInfoParams.file_count = this.dialogConfig.files.length
        // this.dialogConfig.uploadInfoParams.file_count = 1
        // newFile.data = this.dialogConfig.uploadInfoParams
        
        // Start upload
        if (newFile.active !== oldFile.active) {
          // console.log('Start upload', newFile)
        }

        // Upload progress
        if (newFile.progress !== oldFile.progress) {
          // console.log('progress', newFile.progress, newFile)
        }

        // Upload error
        if (newFile.error !== oldFile.error) {
          // console.log('error', newFile.error, newFile)
          this.$emit('fail')
        }

        // Uploaded successfully
        if (newFile.success !== oldFile.success) {
          // console.log('success', newFile.success, newFile)
          this.success(newFile.response)
        }
      }

      if (!newFile && oldFile) {
        // Remove file
        // Automatically delete files on the server
        if (oldFile.success && oldFile.response.id) {
          // $.ajax({
          //   type: 'DELETE',
          //   url: '/file/delete?id=' + oldFile.response.id,
          // });
        }
      }
    },
    onClickDeleteItem(index) {
      this.fileValues.splice(index, 1)
    },
    onClickDeleteAll() {
      this.$refs.upload.clear()
    },
    onClose() {
      this.$emit('change', false)
    },
    success(response) {
      this.$emit('success', response)

      if (this.$refs.upload.uploaded) {
        this.$emit('change', false)
      }
    }
  }
}
</script>

<style scoped>
.drop-active { opacity:0.5; }
.pointer, .pointer label { cursor: pointer }
.pointer :hover, .pointer:hover { opacity: 0.9; }
.pointer :active, .pointer:active{ opacity: 0.5; }
.file-list{
  height: 168px;
  overflow: auto;
  list-style-type: none;
}
.file-name {
  width: 100%;
  padding: 0 8px
}
.file-name:hover {
  background: var(--robin-egg-blue);
}
</style>

<style>
</style>
