import 'babel-polyfill'
import 'es6-promise/auto'

import '@/assets/css/index.scss'
import '@/assets/css/stylus/main.styl'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vuetify from 'vuetify'
import VueCookies from 'vue-cookies'
import i18n from './i18n'

import '@/assets/js'
import 'lazysizes'
import 'lazysizes/plugins/parent-fit/ls.parent-fit'

Vue.use(Vuetify, {
  theme: {
    primary: '#404db2',
    secondary: '#424242',
    accent: '#00edbe',
    error: '#ff8484',
    info: '#8ee5ff',
    success: '#4CAF50',
    warning: '#FFC107'
  },
  iconfont: 'md',
})

Vue.use(VueCookies)

if (window.location.protocol === 'https:') {
  Vue.$cookies.config('10h', '', '', true)
} else {
  Vue.$cookies.config('10h', '', '', false)
}

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount('#app')
