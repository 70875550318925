const environment = {
  SET_LANGUAGE: (state, language) => {
    state.language = language
  },
  SET_WAITING: (state, waiting) => {
    state.waiting = waiting
  },
  SET_DRAWER: (state, drawer) => {
    state.drawer = drawer
  },
}

const common = {
  SET_RESTORE_DISABLED: (state, disabled) => {
    state.buttons.restore.disabled = disabled
  },
  SET_RESTORE_CLICKED: (state, clicked) => {
    state.buttons.restore.clicked = clicked
  },
  SET_SAVE_DISABLED: (state, disabled) => {
    state.buttons.save.disabled = disabled
  },
  SET_SAVE_CLICKED: (state, clicked) => {
    state.buttons.save.clicked = clicked
  },
  SET_COPY_CLICKED: (state, clicked) => {
    state.buttons.copy.clicked = clicked
  },
  SET_PASTE_CLICKED: (state, clicked) => {
    state.buttons.paste.clicked = clicked
  },
  SET_PASTE_DISABLED: (state, disabled) => {
    state.buttons.paste.disabled = disabled
  },
}

const auth = {
  SET_ACCOUNT: (state, account) => {
    state.account = account
  },
  SET_SESSION_KEY: (state, sessionKey) => {
    state.sessionKey = sessionKey
  }
}


const mutations = {
  ...environment,
  ...common,
  ...auth
}

export default mutations