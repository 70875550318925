import api from '@/api'
import VueCookies from 'vue-cookies'
import router from '@/router'

const actions = {
  async SessionCheck({ commit }) {
    const result = await api.login.read({})
    .then((response) => {
      const data = response.data
      const manager = data.manager
      const sessionKey = VueCookies.get('ssKey')
      
      VueCookies.set('ssKey', sessionKey)
      commit('SET_ACCOUNT', manager)
      
      return true
    })
    .catch((err) => {
      console.log(err)
      VueCookies.remove('ssKey')
      router.replace({ name: 'Login' })
      
      return false
    })

    return result
  }
}

export default actions